import {
  PLATFORM_UPLOAD_URL,
  PLATFORM_UPLOAD_JOB_API,
  AUTH_TOKEN,
} from '../../constants';

const getWorldTransform = (threekitApi, nodeId) => {
  const { nodes, evaluatedNodes } = threekitApi.store.get('sceneGraph');
  const { Vector3, Quaternion, Euler, Matrix4 } = threekitApi.THREE;
  if (evaluatedNodes[nodeId]) return evaluatedNodes[nodeId].worldTransform;

  let node = nodes[nodeId];
  const euler = new Euler();
  const quat = new Quaternion();
  const rotateInRadius = new Vector3();
  const worldTrans = new Matrix4();
  const localTrans = new Matrix4();
  while (node.plugs.Transform) {
    const {
      translation,
      rotation,
      scale,
      rotateOrder,
    } = node.plugs.Transform[0];
    rotateInRadius.copy(rotation).multiplyScalar(180 / Math.PI);
    euler.setFromVector3(rotateInRadius, rotateOrder);
    quat.setFromEuler(euler);
    localTrans.compose(
      translation,
      quat,
      scale
    );
    worldTrans.premultiply(localTrans);
    node = nodes[node.parent];
  }
  return worldTrans;
};

export const getWorldTranslation = (threekitApi, nodeId) => {
  const { Vector3 } = threekitApi.THREE;
  return new Vector3().setFromMatrixPosition(
    getWorldTransform(threekitApi, nodeId)
  );
};

export const getAssetInstance = (threekitApi, query) => {
  const { id, plug, property } = query;
  try {
    const asset = threekitApi.store.get('sceneGraph').nodes[id].plugs[plug][0][
      property
    ];
    if (typeof asset === 'string') return asset;
  } catch (e) {}
  return threekitApi.player.getAssetInstance(query);
};

export const getConfiguratorInstance = (threekitApi, query) => {
  const { id, plug, property } = query;
  try {
    const asset = threekitApi.store.get('sceneGraph').nodes[id].plugs[plug][0][
      property
    ];
    if (typeof asset === 'string')
      return threekitApi.scene.get({ id: asset, evalNode: true }).configurator;
  } catch (e) {}
  return threekitApi.player.getConfiguratorInstance(query);
};

export const getNodeBoundingBox = async (threekitApi, nodeId) => {
  const retryFunc = (id) =>
    threekitApi.scene.get({ id, evalNode: true }) &&
    threekitApi.scene.get({ id, evalNode: true }).getBoundingBox();
  return autoRetry(retryFunc, [nodeId]);
};

export const getLidDecalAspectRatio = async (
  threekitApi,
  nodeId,
  optionalBB
) => {
  const bb = optionalBB || (await getNodeBoundingBox(threekitApi, nodeId));

  // hardcode condition, in case if the lid is open
  if (bb.max.y - bb.min.y > 0.003) {
    const width = bb.max.x - bb.min.x;
    const height = Math.sqrt(
      Math.pow(bb.max.y - bb.min.y, 2) + Math.pow(bb.max.z - bb.min.z, 2)
    );
    return width / height;
  } else {
    return (bb.max.x - bb.min.x) / (bb.max.z - bb.min.z);
  }
};

export const autoRetry = async (func, args, timeStep = 500) => {
  let result = null;
  let retryCount = 0;
  do {
    result = await new Promise(async (resolve) => {
      setTimeout(() => resolve(null), timeStep);
      const funcResult = await func(...args);
      resolve(funcResult);
    });
    if (result !== null) return result;
    retryCount += 1;
    await sleep(timeStep);
  } while (retryCount < 10);
};

const sleep = async (time = 1000) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const uploadImageToPlatform = (blob, name) => {
  const formData = new FormData();
  formData.append('files', blob, name);
  return fetch(PLATFORM_UPLOAD_URL, { method: 'POST', body: formData }).then(
    (res) => res.json()
  );
};

const checkAssetId = (jobId) =>
  fetch(`${PLATFORM_UPLOAD_JOB_API}${jobId}?bearer_token=${AUTH_TOKEN}`).then(
    (res) => res.json()
  );

export const getAssetId = (jobId) =>
  new Promise(async (resolve) => {
    let count = 0;
    let assetId;
    while (count < 30 && !assetId) {
      await sleep(2000);
      const checkRes = await checkAssetId(jobId);
      ++count;
      if (checkRes.output) {
        if (checkRes.output.texture.length) {
          assetId = checkRes.output.texture[0].assetId;
        } else if (checkRes.output.vector.length) {
          assetId = checkRes.output.vector[0].assetId;
        }
      }
    }
    resolve(assetId);
  });
